import { render, staticRenderFns } from "./AIGroupHeader.vue?vue&type=template&id=08217709&scoped=true&"
import script from "./AIGroupHeader.vue?vue&type=script&lang=ts&"
export * from "./AIGroupHeader.vue?vue&type=script&lang=ts&"
import style0 from "./AIGroupHeader.vue?vue&type=style&index=0&id=08217709&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08217709",
  null
  
)

export default component.exports